/* menu-footer */
.rotate {
    transform: rotate(-90deg);
}
.newsletter-form {
    
    //max-width: 400px;
    .btn {
        border: 0;
        line-height: 50px;
        padding: 0;
        height: 50px;
        border: 1px solid #fff;
        background-color: #fff;
        color: $marron;
        &:hover, &:focus {
            border: 1px solid #fff;
            background-color: $marron;
            color: $white;
        }
    }
    .form-control {
        border: 1px solid #fff;
        background-color: transparent;
        line-height: 50px;
        padding: 0;
        height: 50px;
        &::placeholder {
            color: $white;
            // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
            opacity: 1;
        } 
    }
    
}
.menu-footer {
    list-style: none;
    margin: 0;
    padding: 0;
    .icon {
        font-size: 1.3rem;
    }
    a {
        color: #fff;
        &:hover,&:focus {
            text-decoration: none;
        }
    }
}
.menu-columns {
    column-count: 2;
    column-gap: 20px;
    padding-left: 20px;
    li {
        margin-bottom: 0.5rem;
    }
}

.menu-column {
    padding-left: 20px;
    li {
        margin-bottom: 0.5rem;
    }
}

.prefooter{
    margin-top: 0;
    background-color: $marron;
    color: #fff;
    a {
        color: #fff;
        &:hover, &:focus {
            color: $gris-claro;
        }
    }
}
.footer {
    margin-top: 0;
    text-align: left;
    background-color: $gris;
    //color: $white;
    @include media-breakpoint-up(sm) {
        text-align: left;
    }
  
    a {
        color: #000;
        &:hover, &:focus {
            text-decoration: none;
            color: $primary;
        }
    }
    .caja-widget {
     
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        height: 100%;
    }


    .menu {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        margin: 0 auto !important;
        @include media-breakpoint-up(sm) {
            display: inline-block;
            margin: 0  !important;
        }
    }


    .menu-footer {
        list-style: none;
        margin: 0;
        padding: 0;
        
        width: 100%;
        @include media-breakpoint-up(sm) {
            display: flex;
        }
        li {
            padding-right: 2rem;
        }
    }


    .social {
        
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        li {
            font-size: 2rem;
            display: inline;
            position: static;
            margin: 0;
            padding: 0 10px 0 0;
            line-height: 1;
            //color: $gray-600;
            a {
                //color: $gray-600;
                display: block;
                text-decoration: none;
                &:hover, &:focus {
                    color: $primary;
                }
            }
            &.facebook a:hover, &.facebook a:focus {
                color: $facebook !important;
            }
            &.twitter a:hover, &.twitter a:focus   {
                color: $twitter;
            }
            &.linkedin a:hover, &.linkedin a:focus   {
                color: $linkedin;
            }
            &.youtube a:hover, &.youtube a:focus   {
                color: $youtube;
            }
            &.instagram a:hover, &.instagram a:focus   {
                color: $instagram;
            }
        }
        
    }

    .legal-footer {
        list-style: none;
        margin: 0;
        padding: 0;
        display: block;
        margin: 0 ;
        width: 100%;
        li {
            display: block;
            position: static;
            margin-top: 0;
            padding: 0 5px 0 0;
            @include media-breakpoint-up(sm) {
                 padding: 0 15px 0 0;
            }
            a {
                text-decoration: none;
              
                &:hover, &:focus {
                    text-decoration: underline;
                    
                }
            }
        }
    }
    .col-footer-right  {
        @include media-breakpoint-up(lg) {
            text-align: right;
        }
        .menu-social  {
            @include media-breakpoint-up(lg) {
                margin-left: auto;
            }
        }
    }
}

