
@font-face {
    font-family: 'uab-icons';
    src: url('../assets/fonts/uab-icons.eot');
    src: url('../assets/fonts/uab-icons.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/uab-icons.woff') format('woff'),
        url('../assets/fonts/uab-icons.ttf') format('truetype'),
        url('../assets/fonts/uab-icons.svg#uab-icons') format('svg');
    font-weight: 500;
    font-style: normal;
}


[class^="icon-"], [class*=" icon-"] {
  font-family: 'uab-icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; 
}


.icon-carrow-up:before {
  content: "\e947";
}
.icon-carrow-right:before {
  content: "\e949";
}
.icon-carrow-down:before {
  content: "\e94a";
}
.icon-carrow-left:before {
  content: "\e94b";
}
.icon-quotes:before {
  content: "\e948";
}
.icon-pdf:before {
  content: "\e938";
}
.icon-linkedin2:before {
  content: "\e606";
}
.icon-google2:before {
  content: "\e607";
}
.icon-facebook2:before {
  content: "\e608";
}
.icon-youtube2:before {
  content: "\e609";
}
.icon-twitter2:before {
  content: "\e60a";
}
.icon-instagram2:before {
  content: "\e901";
}
.icon-lupa:before {
  content: "\e60d";
}
.icon-f-left:before {
  content: "\e620";
}
.icon-f-right:before {
  content: "\e621";
}
.icon-svg:before {
  content: "\e62e";
}
.icon-svg2:before {
  content: "\e62f";
}
