
/*
$gris: #7a7068;
$gris-oscuro: #2e2f2f;
$gris-claro: #dddfe1;
$malva: #9a8da5;
$malva-claro: #f5f5f5;
$malva-oscuro: #42334d;
$marron: #ab8770;
$marron-claro: #c49e8a;
$marron-oscuro: #866168;


$aguamarina-claro: #66FFCC;
$aguamarina: #2FD9B3;
$aguamarina-oscuro: #26BFA6;

$verde: #0E4136;
$verde-oscuro: #051612 ;

$amarillo-claro: #FFE491;
$amarillo: #FFCC33;

*/
.text-aguamarina-claro {
    color: $aguamarina-claro;
}

.text-aguamarina {
    color: $aguamarina;
}

.text-aguamarina-oscuro {
    color: $aguamarina-oscuro;
}
.text-verde {
    color: $verde;
}
.text-verde-oscuro {
    color: $verde-oscuro;
}
.text-amarillo-claro {
    color: $amarillo-claro;
}
.text-amarillo {
    color: $amarillo;
}


.text-gris {
    color: $gris;
}
.text-gris-oscuro {
    color: $gris-oscuro;
}
.text-gris-claro {
    color: $gris-claro;
}
.text-malva {
    color: $malva;
}
.text-malva-claro {
    color: $malva-claro;
}
.text-malva-oscuro {
    color: $malva-oscuro;
}
.text-marron {
    color: $marron;
}
.text-marron-claro {
    color: $marron-claro;
}
.text-marron-oscuro {
    color: $marron-oscuro;
}

.bg-aguamarina-claro  {
    background-color: $aguamarina-claro ;
}
.bg-aguamarina  {
    background-color: $aguamarina ;
}

.bg-aguamarina-oscuro  {
    background-color: $aguamarina-oscuro ;
}

.bg-amarillo  {
    background-color: $amarillo ;
}

.bg-amarillo-claro  {
    background-color: $amarillo-claro ;
}

.bg-verde  {
    background-color: $verde ;
}

.bg-verde-oscuro  {
    background-color: $verde-oscuro ;
}

.bg-gris {
    background-color: $gris;
}
.bg-gris-oscuro {
    background-color: $gris-oscuro;
}
.bg-gris-claro {
    background-color: $gris-claro;
}
.bg-malva {
    background-color: $malva;
}
.bg-malva-claro {
    background-color: $malva-claro;
}
.bg-malva-oscuro {
    background-color: $malva-oscuro;
}
.bg-marron {
    background-color: $marron;
}
.bg-marron-claro {
    background-color: $marron-claro;
}
.bg-marron-oscuro {
    background-color: $marron-oscuro;
}

.bg-gris-oscuro {
    background-color: $gris-oscuro;
}


body {
    //color: $gray-700;
    font-variant-ligatures: none;
    overflow-x: hidden;
}

* { 
    -webkit-font-smoothing: antialiased; 
    -moz-osx-font-smoothing: grayscale; 

}

.ei:before {
    vertical-align: middle;
}

@media (min-width: 1400px) {
    .container {
        max-width: 1340px;
    }
}

@media (min-width: 1800px) {
    .container {
        //max-width: 1740px;
    }
}


@media (max-width: 800px) {
    .display-3 {
        font-size: 2.5rem;
    }
}

a {
    transition: all .2s ease-in-out; 
    color: $secondary;
}

img {
    max-width: 100%;
    height: auto;
}

.btn, .form-control {
    border-radius: 0;
}

h1, .h1 { font-family: $font-title; color: $secondary; }
h2, .h2 { font-family: $font-title; color: $secondary;}
h3, .h3 { font-family: $font-title; color: $secondary;}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    margin-bottom: 1rem;
    font-weight: bold;
}

.normal-font {
    font-family: $font-family-sans-serif;
}

.special h5 {
    line-height: 1.4 !important;
}


.ei:before {
    vertical-align: middle;
}

.fluidvids {
    margin: 2rem auto;
}

.columns {
    column-rule: 0;
    column-count: 1;
    column-gap: 0;

    @include media-breakpoint-up(md) {
        column-count: 2;
        column-gap: 1.5rem;
    }

    @include media-breakpoint-up(lg) {
        column-count: 1;
        column-gap: 0;
    }

    @include media-breakpoint-up(xl) {
        column-count: 2;
        column-gap: 1.5rem;
    }
}

.custom-list {
    ul {
        list-style: none; /* Remove default bullets */
        padding-left: 1rem;
        margin: 1rem 0;
        li {
            margin: 0 0 1rem 0;
            &:before {
                content: '\4e';  /* Add content: \2022 is the CSS Code/unicode for a bullet */
                color: $primary; /* Change the color */
                font-family: 'ElegantIcons'; /* If you want it to be bold */
                display: inline-block; /* Needed to add space between the bullet and the text */
                width: 1.3rem; /* Also needed for space (tweak if needed) */
                margin-left: -1rem; /* Also needed for space (tweak if needed) */
                font-weight: bold;
            }
        }
    }
}



.mb-grid {
    margin-bottom: 30px;
}

.section-title-section {
    position: relative;
    .icon {
        position: absolute;
        top: -30px;
        right: -50px;
        left: auto;
        width: 180px;
        height: auto;
        opacity: 0.5;
        @include media-breakpoint-up(md) {
            top: -50px;
            width: 280px;
            right: 0;
            opacity: 1;
        }
    }
}



.section-title {
    margin-top: 3rem;
    margin-bottom: 1rem;
    text-align: center;
    color: $body-color;
    //text-transform: uppercase;
    font-weight: bold;
    font-size: 2rem;
    position: relative;
  
    em {
        font-weight: lighter;
        font-size: 1rem;
        margin-bottom: 1rem;
        font-style: normal;
        display: inline-block;
    }
}

.border-title {
    width: 80px;
    border-bottom: 3px solid $body-color;
    margin: 0 auto 3rem auto
}

.bg-gris {
    background-color: #eee;
}

.bg-title {
    margin-top: 1rem;
    margin-bottom: 1rem;
    text-align: center;
    color: $white;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 2rem;
    position: relative;
  
    em {
        font-weight: lighter;
        font-size: 1rem;
        margin-bottom: 1rem;
        font-style: normal;
        display: inline-block;
    }
}

.bg-border-title {
    width: 80px;
    border-bottom: 3px solid $white;
    margin: 0 auto 1rem auto
}



// play
.play-btn {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    background-color: $primary;
    border-radius: 50%;
}





.overflow {
    overflow: visible !important;
}

.position-under {
    position: relative;
    z-index: 1;
}



// security
.grecaptcha-badge { visibility: hidden; }



.featured {
    margin: 3rem auto 2rem auto;
}


.gradient-text {
    width: 100%;
    margin: 3rem auto 2rem auto;
    display: flex;
	align-items: center;
	justify-content: center;
    color: $primary;
	background: #fff;
    .text {

        /* standard gradient background */
        background: linear-gradient($primary, $secondary);
        background-clip: text;
      
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
	
}


.font-title {
    font-family: $font-family-sans-serif;
}

.font-custom, .custom-font{
    font-family: $font-custom !important;
}

.font-weight-bold {
  font-weight: 900 !important;
}



@keyframes blurFadeIn {
    0% {
        opacity: 0;
        //transform: scale(1.3);
    }

    /*
    50% {
        opacity: 0.5;
        transform: scale(1.1);
    }
    */
    100% {
        opacity: 1;
        //transform: scale(1);
    }
}

.fade-in-element {
    animation: blurFadeIn 1.4s ease-in backwards;
}

.table td, .table th {
    border-top: 1px solid $primary;
}
.table thead th {
    border-top: 0;
    border-bottom: 1px solid $primary;
    text-transform: uppercase;
}



.btn-min {
    min-width: 200px;
    text-transform: uppercase;
    
}

.btn-outline-dark {
    &:hover, &:focus {
        background-color: $primary;
        border-color: $primary;
    }
}

.btn-primary {
    background-color: $primary;
    border-color:  $primary;
    color: $white;
    transition: 0.25s !important;
    &:hover, &:focus {
        background-color: #9e9e9e !important;
        border-color:  #9e9e9e !important;
        color: $white !important;
    }
}


.content {
    overflow-x: hidden;
    min-height: 80vh;
    img {
        max-width: 100%;
        height: auto;
    }
}



.map-text {
    font-size: 14px;
    .btn {
        font-size: 13px !important;
    }
}


.icono_texto {
    border-bottom: 5px solid $white;
    padding-bottom: 1.5rem;
    .image {
        img {
            max-width: 60%;
            margin: 0 auto 1.5rem auto;
        }
    }
    .text {

    }
}


.logos {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    a {
        display: inline-block;
    }
    img {
        
        height: auto;
        width: auto;
        max-height: 30px;
        max-width: 130px;
        @include media-breakpoint-up(lg) {
            max-height: 60px;
            max-width: 170px;
        }
    }
}

.logos-2 {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    img {
        width: 50%;
        height: auto;  
    }
}


.map-item {
    width: 100%;
    height: 300px;
    @include media-breakpoint-up(lg) {
        height: 400px;
    }
}




.error-404 {
    width: 100%;
    height: calc(100vh - 100px);
    background-color: #9e9e9e;
    display: flex;
    align-items: center;
    margin-bottom: -2rem;
}


.mfp-iframe-holder .mfp-content {
    //max-width: 100%;
}

.alignleft {
    display: inline;
    float: left;
    margin-right: 15px;
    margin-bottom: 15px;
  
}

.alignright {
    display: inline;
    float: right;
    margin-left: 15px;
    margin-bottom: 15px;

}


.room-bg {
    background-color: #fff;
}
