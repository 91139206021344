/* logo  */
.icon-logo {

    text-transform: uppercase;
    float: left;
    display: inline;
    width: auto !important;
    height: 60px !important;
    line-height: inherit;
    white-space: nowrap;
    display: inline-block;
    font-size: 1rem;
    margin: 10px 0;
    @include transition($transition-base);
    @include media-breakpoint-up(md) {
        width: auto !important;
        height: 60px !important;
    }

}


/* header */
.header {
    background: rgba($white, 1);
    position: absolute;
    top: 20px;
    left: 0;
    right: 0;
    z-index: 900;
    &.shrink {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1030;
    }
    a {
        color: $black;
        &:hover, &:focus {
            text-decoration: none;
            color: $secondary;
        }
    }
    .navbar {
        .container {
            @include media-breakpoint-up(lg) {
                align-items: flex-start;
            }
        }
    }
    .navbar-nav {
        padding: 0;
        text-transform: uppercase;

        @include media-breakpoint-up(lg) {
            margin: 0;
        }

        .nav-link {
            border-top: 1px solid $gray;
            @include media-breakpoint-up(lg) {
                border-top: 0;
                line-height: 30px;
                padding: 0;

                a {
                    margin: 0 1rem;
                    padding: 0;
                    display: block;
                    position: relative;
                    //border-bottom: 1px solid $white;
                   
                    @include media-breakpoint-up(lg) {
                        margin: 0 0.5rem;
                    }
                    @include media-breakpoint-up(xl) {
                        margin: 0 0.6rem;
                    }
                 
                }
                a:hover, a:focus {
                    //border-bottom: 1px solid $primary;
                    color: $primary;
                    outline: 0;
                }
                &.active a, &.current_page_parent a {
                    //border-bottom: 1px solid $primary;
                    color: $primary;
                }
            }
        }

        >li {
            @include media-breakpoint-up(lg) {
                position: static;
            }
            
           
        }
    }

    .navbar-collapse {
        margin-top: auto;
        margin-bottom: auto;
        @include media-breakpoint-up(lg) {
            max-width: 680px;
        }
        @include media-breakpoint-up(xl) {
            max-width: 900px;
        }
    }

    .menu {
        list-style: none;
        li {
            display: inline-block;
            a {
                margin: 0 0.5rem;
                
                &:hover, &:focus {
                    //border-bottom: 1px solid #000;
                    color: #000;
                }
            }
            &.active a {
                color: $secondary;
                //border-bottom: 1px solid #000;
                color: #000;
            }
        }
    }

    .mega-dropdown-menu {
        border-radius: 0 !important;
        background: $primary;
        left: 0 !important;
        right: 0 !important;
        padding: 0;
        border: 0;
        margin: 0;
        height: 0;
        visibility: hidden;
        opacity: 0;
        @include transition($transition-base);
        //transition: all .5s ease;
        display: block;
        transform: scale(1, 0) ;
        transform-origin: 0 0;
        &.show {
            height: auto;
            transform: scale(1, 1) ;
            visibility: visible;
            opacity: 1;
        }
        ul {
            padding: 0;
            list-style: none;
            @include media-breakpoint-up(lg) {
                float: right;
                padding-right: 12rem;
            }
            li {
                @include media-breakpoint-up(lg) {
                    float: left;
                    display: inline;
                }
                a {
                    color: $white !important;
                    font-weight: normal !important;
                    @include media-breakpoint-up(lg) {
                        border-top: 0 !important;
                    }

                }
                &.active a, a:focus, a:hover {
                    color: $black !important;
                    outline: 0;
                }
            }
        }



    }
 

}


.dropdown-toggle::after {
    @include media-breakpoint-up(lg) {
        display: inline-block;
        margin: 0;
        vertical-align: middle;
        content: "";
        width: 0;
        height: 0;
        position: absolute;
        bottom: 0;
        left: 50%;

        margin-left: -10px;
        border: 10px solid;
        border-color: transparent transparent $primary transparent;
    }
}


.menu-social {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    margin: 0;
    padding: 0;
    li {
        display: inline-block;
        padding-left: 0;
        padding-right: 0.5rem;

        
    }
}

.lang {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    margin: 1rem 0 2rem 0;
    padding: 0;
    font-size: 80%;
    @include media-breakpoint-up(lg) {
        margin: 0 0 0 1rem;
    }
    @include media-breakpoint-up(xl) {
        margin: 0 0 0 1rem;
    }
    li {
        display: flex;
        align-items: center;
        padding-left: 0.5rem;
        padding-right: 0;
        line-height: 1.1;
        text-transform: uppercase;
        @include media-breakpoint-up(lg) {
            padding-left: 0.2rem;
        }
        @include media-breakpoint-up(xl) {
            padding-left: 0.5rem;
        }
        &:after {
            content: '/';
            padding-left: 0.5rem;
            @include media-breakpoint-up(lg) {
                padding-left: 0.2rem;
            }
            @include media-breakpoint-up(xl) {
                padding-left: 0.5rem;
            }
        }
        &:last-child {
            padding-right: 0;
            &:after {
                content: '';
                padding-left: 0;
            }
        }
        &:first-child {
            padding-left: 0;
        }
        a {
            display: block;
            position: relative;
            &:hover, &:focus {
                text-decoration: none;
                color: $primary;
            }
        }
        &.active {
            a {
                color: $secondary;
            }
        }
 
    }
}

.login {
    text-align: right;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    margin: 1rem 0 1rem auto;
    padding: 0;
    font-size: 80%;
    @include media-breakpoint-up(lg) {
        margin: 0 0 0 1rem;
    }
    @include media-breakpoint-up(xl) {
        margin: 0 0 0 3rem;
    }
    li {
        display: inline-block;
        padding-left: 0.5rem;
        padding-right: 0;
        line-height: 1.1;
        text-transform: uppercase;
     
        @include media-breakpoint-up(lg) {
            padding-left: 0.5rem;
        }
        &:after {
            content: '';
        }
        &:last-child {
            padding-right: 0;
            &:after {
                content: '';
                padding-left: 0;
            }
        }
        &:first-child {
            padding-left: 0;
        }
        a {
            display: block;
            position: relative;
            &:hover, &:focus {
                text-decoration: none;
                color: $primary;
            }
        }
 
    }
    .login-form {
        min-width: 290px;
        position: absolute;
        top: 100%;
        z-index: 9999;
        background-color: $white;
        transform: translateX(-220px);

        background-color: $white;
        @include media-breakpoint-up(lg) {
            min-width: 290px;
            position: absolute;
            top: 100%;
            z-index: 9999;
            background-color: $white;
            transform: translateX(-220px);
        }
        h3 {
            padding: 1rem;
        }
    }
}



