.navbar-toggler {
    padding: 0;
    border-radius: 0;
    outline: 0;
    position: relative;
    z-index: 950;

    span {
        display: table-cell;
        vertical-align: middle;

        em {

            /* this is the menu icon */
            display: block;
            position: relative;
            height: 3px;
            width: 30px;
            background-color: $body-color;
            backface-visibility: hidden;

            &::after {

                /* this is the menu icon */
                display: block;
                position: relative;
                height: 3px;
                width: 30px;
                background-color: $body-color;
                backface-visibility: hidden;
            }

            &::before {

                /* this is the menu icon */
                display: block;
                position: relative;
                height: 3px;
                width: 30px;
                background-color: $body-color;
                backface-visibility: hidden;
                position: absolute;
                content: '';
                left: 0;
                transition: transform .2s;
            }

            /* this is the menu central line */
            margin: 10px auto;
            transition: background-color .2s;
            background-color: rgba(255, 0, 0, 0);

            &::after {
                position: absolute;
                content: '';
                left: 0;
                transition: transform .2s;
            }

            &::before {

                /* this is the menu icon top line */
                transform: rotate(-45deg);
            }

            &::after {

                /* this is the menu icon bottom line */
                transform: rotate(45deg);
            }

        }

    }

    &.collapsed span em {

        /* transform menu icon into a 'X' icon */
        background-color: $body-color;

        &::before {

            /* rotate top line */
            transform: translateY(-10px);
        }

        &::after {

            /* rotate bottom line */
            transform: translateY(10px);
        }

    }

    &:hover,
    &:focus {
        box-shadow: none;
        outline: none;
    }
}