.video-img {
   
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
    &:before {
        padding-top: 56.25%;
        display: block;
        content: "";
    }
    .text-title {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 900;
        text-align: center;
        background-color: rgba($gris-oscuro,0.5);
    }
    .video-resum {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 900;
        background-color: rgba($gris-claro,0.8);
        display: none;
        @include media-breakpoint-up(md) {
            display: block;
        }
    }
    a {
        &:hover, &:focus {
            text-decoration: none;
            
            img {
                transform: translate(-50%, -50%) scale(1.1) ; 
            }
            .read-more {
                opacity: 1;
            }
            
        }
    }
    
    img {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: auto;
        border: 0;
        transition: all .2s ease-in-out; 
        transform: translate(-50%, -50%);
    }
    .read-more {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba($primary, 0.8);
        color: $white;
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
        width: 100%;
        border: 0;
        text-transform: uppercase;
        opacity: 0;
        transition: all .2s ease-in-out; 
    }
    .play {
    	left: 50%;
	    top: 50%;
	    bottom: auto;
	    right: auto;
        transform: translateX(-50%) translateY(-50%);
        width: 50px;
        height: 50px;
        background-color: transparent;
        background-image: url('../assets/img/play.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        color: #fff;
        position: absolute;
        text-align: center;
        z-index: 30;
    }
    
    .title {
    	position: absolute;
    	bottom: 0;
    	left: 0;
    	right: 0;
    	background-color: rgba(255,255,255,0.75);
    	text-align: center;
    	text-transform: uppercase;
    	color: $primary;
    	z-index: 30;

    }
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100% !important;
    }
}


.video-link {
    display: block;
    overflow: hidden;
    .video-img {
        .title {

        }
    }
    &:hover, &:focus {
        display: block;
        img {
            transform: translate(-50%, -50%)  scale(1.1); 
        }
        .video-img {
            .title {
                color: $white;
                background-color: rgba($primary,0.75);
            }
        }
    }
}