/* owl-carousel */
$owl-color-base:            $primary !default;
$owl-color-white:           $primary !default;
$owl-color-gray:            #D6D6D6 !default;

//nav
$owl-nav-color:             $owl-color-white !default;
$owl-nav-color-hover:       $owl-color-white !default;
$owl-nav-font-size:         60px !default;
$owl-nav-rounded:           3px !default;
$owl-nav-margin:            5px !default;
$owl-nav-padding:           4px 7px !default;
$owl-nav-background:        $owl-color-gray !default;
$owl-nav-background-hover:  $owl-color-base !default;
$owl-nav-disabled-opacity:  0.8 !default;

//dots
$owl-dot-width:             10px !default;
$owl-dot-height:            10px !default;
$owl-dot-rounded:           30px !default;
$owl-dot-margin:            5px !default;
$owl-dot-background:        $owl-color-gray !default;
$owl-dot-background-active: $owl-color-base !default;



.owl-carousel {
    .owl-nav {
        margin-top: 0;
        text-align: center;
        -webkit-tap-highlight-color: transparent;
        [class*='owl-'] {
            color: #000 !important;
            background-color: $gris-claro !important;
            display: none;
            cursor: pointer;
            border-radius: 0;
            width: 70px;
            height: 70px;
            line-height: 1 !important;
            font-size: 50px !important;
            text-align: center;
            position: absolute;
            top: 50%;
            margin: -65px 0 0 0;
            vertical-align: middle;
            @include media-breakpoint-up(lg) {
                display: flex;
                align-items: center;
                justify-content: center;
                vertical-align: middle;
            }
            &:hover, &:focus {
                color: $primary!important;
                text-decoration: none;
                outline: none;
            }
            .ei {
                line-height: 1;
            }
            .ei:before {
                vertical-align: middle;
            }
            
        }
        .owl-prev {
            left: 0;
            display: none;
            @include media-breakpoint-up(md) {
                left: -90px;
                display: flex;
            }
        }
        .owl-next {
            right: 0;
            display: none;
            @include media-breakpoint-up(md) {
                right: -90px;
                display: flex;
            }
        }
        .disabled {
            opacity: $owl-nav-disabled-opacity;
            cursor: default;
        }
    }

    // Styling dots
    .owl-nav.disabled + .owl-dots {
        margin-top: 10px;
    }

    .owl-dots {
        text-align: center;
        -webkit-tap-highlight-color: transparent;
        position: relative;
		margin-top: 1.5rem;
        .owl-dot {
            display: inline-block;
            zoom: 1;
            *display: inline;

            span {
                width: $owl-dot-width;
                height: $owl-dot-height;
                margin: $owl-dot-margin;
                background: $owl-dot-background;
                display: block;
                -webkit-backface-visibility: visible;
                transition: opacity 200ms ease;
                border-radius: 50%;
            }

            &.active,
            &:hover, &:focus {
                outline: none;
                span {
                    background: $owl-dot-background-active;
                }
            }
        }
    }
}
