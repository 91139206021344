
.btn-ayuda {
    .ayuda-text {
        color: $gray-900;
        background-color: $white;
        outline: 0;
        user-select: none;
        border: 0;
        &:hover, &:focus {
            color: $gray-900;
            background-color: $white;
        }

    }

    .ayuda-btn {
        color: $white;
        background-color: $primary;
        outline: 0;
        user-select: none;
        border: 0;
        font-weight: bold;
        &:hover, &:focus {
            color: $white;
            background-color: $verde !important;
        }
    }
    .newsletter-btn {
        color: $white;
        background-color: $primary;
        outline: 0;
        user-select: none;
        border: 0;
        font-weight: bold;
        &:hover, &:focus {
            color: $white;
            background-color: $verde !important;
        }
    }
    &:hover, &:focus {
        text-decoration: none;
    }
}

.newsletter-block {
    position: relative;
    .newsletter-icon {
        width: auto;
        height: 90%;
        max-width: auto;
        position: absolute;
        bottom: 0;
        left: 10%;
    }
}

.faqs-search {
    z-index: 900;
}

.ayuda-faqs {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: $white;
}
.faq_faqs  {
    border-bottom:1px solid #000;
    margin-bottom: 1rem;
}

.faq-title {
    a {
        &:hover, &:focus {
            text-decoration: none;
        }
        &.collapsed {
            .fa-minus-circle:before {
                content: "\f055";
            }
        }

    }
}



/* testimonios */
.testimonios {
    .wpb_wrapper {
        display: flex;
    }
}

.slide-testimonis {
    margin: 0;
    clear: both;
    .owl-stage-outer {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        height: 100%;
    }
    .owl-stage {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        height: 100%;
        
    }
    .owl-item {
        display: flex;
        
    }
    .resum-testimoni {
        display: flex;
        width: 100%;
    }
    .testimoni-content {
        padding: 40px 60px;
        background-color: $amarillo;
        height: 100%;
        position: relative;
        &:after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 30px 0 0 30px;
            border-color: #fff #fff #fff #caa229;
        }
    }
    .owl-nav {
        [class*='owl-'] {
            width: 50px;
            height: 70px;
            line-height: 70px !important;
            font-size: 30px !important;
        }
        .owl-prev {
            color: $black !important;
            left: 0;
        }

        .owl-next {
            color: $black !important;
            right: 0;
        }
    }   
    
}





/* servicios */
.featured-service {
    text-transform: uppercase;
    margin: 0 auto;
    text-align: center;
    color: $gris-oscuro;
    ul {
        list-style: none;
        margin: 0 auto;
        padding:0;
        li {

        }
    }
    a {
        color: $gris-oscuro;
        background: transparent;
        border-radius: 50rem !important;
        padding: 2px 20px;
        margin-bottom: 5px;
        display: inline-block;
        border: 1px solid transparent;
        &:hover, &:focus {
            text-decoration: none;
            border: 1px solid $gris-oscuro;
        }
    }

}

/* chico y chica */
@keyframes fadeLeft {
	0% {
		opacity: 0;
        transform: translateY(100px);
	}
	60% {
		opacity: 0.5;
	}
	80% {
		opacity: 0.9;
	}
	100% {
		opacity: 1;
        transform: translateY(0px);
	}
}


@keyframes fadeRight {
	0% {
		opacity: 0;
        transform: translateY(100px);
	}
	60% {
		opacity: 0.5;
	}
	80% {
		opacity: 0.9;
	}
	100% {
		opacity: 1;
        transform: translateY(0px);
	}
}

.show-on-scroll {
    .chica {
        opacity: 0;

    }
    .chico {
        opacity: 0;
    }
    &.is-visible {
        .chica {
            
            opacity: 0;
            animation: bounceInLeft 1.9s forwards; 
            animation-delay: 0.2s;

        }
        .chico {
           
            opacity: 0;
            animation: bounceInRight 1.9s forwards; 
            animation-delay: 0.4s;
        }
    }
}


/* cta */
.cta {
    width: 100%;
    min-height: 400px;
    position: relative;
    display: flex;
    flex-direction: column;
    padding-top: 60px;
    padding-bottom: 60px;
    overflow: hidden;
    .cta-buttons {
       background-color: rgba($malva, 0.5);
       padding: 10px 0;
    }
    &.cta-3 {

    }
    .page-title {
        margin-top: 100px;
    }
    .parallax- {
        width: 100%;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-size: cover;
        background-position: center center;
    }
}

.borders {
    border-top: 3px solid $secondary;
    border-bottom: 3px solid $secondary;
    h1, h2, h3, h4 {
        color: $secondary;
    }
}
.cosina-line {
    width: 100%;
    border-top: 3px solid $secondary;
}

.bg-image {
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-size: cover;
    background-position: center center;
}

.bg-cover {
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0,0.5);
    //mix-blend-mode: multiply;
}

.bg-cover-primary {
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba($primary,0.2);
    mix-blend-mode: multiply;
}

.small-btn {
    width: 100%;
    margin: 10px auto;
    border-radius: 50rem !important;
    border: 1px solid #fff;
    color: #fff;
    text-align: center;
    padding: 2px 20px;
    display: block;
    text-transform: uppercase;
    font-weight: bold;
    &:hover, &:focus {
        text-decoration: none;
        background-color: $white;
        color: $gris-oscuro;
    }
}

.btn-gris {
    width: 100%;
    margin: 10px auto;
    border-radius: 0 !important;
    text-align: center;
    padding: 10px 20px;
    display: block;
    text-transform: uppercase;
    font-weight: bold;
    background-color: $gris-oscuro;
    border: 1px solid $gris-oscuro;
    color: #fff;
    &:hover, &:focus {
        text-decoration: none;
        background-color: $white;
        color: $gris-oscuro;
    }
}

.btn-trans {
    width: 100%;
    margin: 10px auto;
    border-radius: 0 !important;
    text-align: center;
    padding: 10px 20px;
    display: block;
    text-transform: uppercase;
    font-weight: bold;
    background-color: transparent;
    border: 1px solid $white;
    color: $white;
    &:hover, &:focus {
        text-decoration: none;
        background-color: $white;
        color: $gris-oscuro;
    }
}

/* blockquote */

blockquote, .blockquote {
    quotes: "\201C""\201D""\2018""\2019";
    
    //font-size: 1.5rem;
    //line-height: 1.1;
    font-style: normal;
    position: relative;
    margin: 3rem 0 !important;
    padding: 2rem 0 !important;
    font-weight: bold;
    display: inline-block;
    color: $secondary;
    width: 100%;
    text-align: center;
    @include media-breakpoint-up(lg) {
        margin: 3rem 0 !important;
    }
    p {
        width: 100%;
        max-width: 800px;
        position: relative;
        z-index: 10;
        margin: 0 auto;
    }
    .quote {
        position: relative;
        z-index: 800;
    }
}

blockquote:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 3px;
    background-color: $secondary;
    font-family: Times;
    z-index: 1;
    opacity: 1;
}

blockquote:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 3px;
    background-color: $secondary;
    font-family: Times;
    z-index: 1;
    opacity: 1;
}




/* title */
.text-title {
    font-weight: lighter;
    line-height: 1.1;
    b, strong {
        font-weight: normal;
    }
}

.im-cover {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba($gris, 0.5);
    mix-blend-mode: multiply;
}
.im-content {
   
    margin: 0 auto !important;
}
.vc_row-flex {
    .wpb_wrapper {
        height: 100%;
    }
}



    .post-projecte {
        margin-bottom: 30px;
        .foto-image:before {
             //padding-bottom: 100%;
         }
        .text-content {
            width: 100%;
            position: absolute !important;
            bottom: 0;
            left: 0;
            right: 0;
         }
    }


.cats {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-self: center;
    justify-content: center;
    li {
        a {
            padding: 0.5rem 1rem;
            display: block;
            border: 1px solid transparent;
            &:hover, &:focus {
                border: 1px solid $secondary;
                color: $secondary;
                text-decoration: none;
            }
        }
    }
}


.cats-single {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-self: center;
    justify-content: flex-start;
    li {
        a {
            padding: 0.5rem 1rem;
            display: block;
            border: 1px solid transparent;
            color: #fff;
            background-color: $secondary;
            border-radius: 50rem !important;
            margin: 0 0.5rem 0.5rem 0;
            &:hover, &:focus {
                border: 1px solid transparent;
                text-decoration: none;
                color: #fff;
                background-color: $primary;
            }
        }
    }
}

.bg-descripcio {
    position: relative;
    &:after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 100%;
        width: 1000px;
        background-color: $secondary;
    }
}

.project-title {
    padding-bottom: 10px;
    position: relative;
    &:after {
        content: '';
        max-width: 300px;
        width: 100%;
        height: 3px;
        background-color: $secondary;
        position: absolute;
        bottom: 0;
        left: 0;
    }
    &.project-title-white {
        &:after {
            background-color: $white;
        }
    }
}


.menu-social-container {
    display: flex;
}
.menu_social {
    list-style: none;
    margin: 0 0 0 auto;
    padding: 0;
    display: flex;
    li {
        font-size: 2rem;
        display: inline;
        position: static;
        margin: 0;
        padding: 0 0 0 10px;
        line-height: 1;
        //color: $gray-600;
        a {
            //color: $gray-600;
            display: block;
            text-decoration: none;
            &:hover, &:focus {
                color: $primary;
            }
        }
        &.facebook a:hover, &.facebook a:focus {
            color: $facebook !important;
        }
        &.twitter a:hover, &.twitter a:focus   {
            color: $twitter;
        }
        &.linkedin a:hover, &.linkedin a:focus   {
            color: $linkedin;
        }
        &.youtube a:hover, &.youtube a:focus   {
            color: $youtube;
        }
        &.instagram a:hover, &.instagram a:focus   {
            color: $instagram;
        }
    }
    
}