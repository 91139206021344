
.resum.post {
    .foto-image {
       margin: 0;
        position: relative;
        display: block;
        width: 100%;
        padding: 0;
        overflow: hidden;
        border: 10px solid #fff;
        &:before {
            padding-top: 66.666;
            display: block;
            content: "";
        }
        img {
            position: absolute;
            top: 50%;
            left: 50%;
            width: auto;
            height: 100%;
            max-width: initial !important;
            border: 0;
            transition: all .2s ease-in-out; 
            transform: translate(-50%, -50%);
        }
        .read-more {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: rgba($primary, 0.8);

            color: $white;
            display: flex;
            align-content: center;
            align-items: center;
            justify-content: center;
            width: 100%;
            border: 0;
            text-transform: uppercase;
            opacity: 0;
            transition: all .2s ease-in-out; 
            mix-blend-mode: multiply;
        }

    }
}


.mosaic {

    width: 100%;
}

.mosaic-grid-sizer,
.mosaic-grid-item {
    width: 100%;
    @include media-breakpoint-up(md) {
        width: 50%;
    }
    @include media-breakpoint-up(lg) {
        width: 33.3333%;
    }
}

.mosaic-grid-item {
    margin: 0;
    float: left;
    //background: #D26;
    height: auto;
    min-height: 100px;
    display: inline-block;
}






// Page Navi

.categorias-nav {
    list-style: none;
    margin: 0 0 2rem 0;
    padding:0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    border-top: 1px solid $secondary;
    border-bottom: 1px solid $secondary;
    li {
        padding:0.5rem 1rem;
        margin: 0;
        text-transform: uppercase;
        a {
            color: $secondary;
            &:hover, &:focus {
                color: $primary;
                text-decoration: none;
            }
        }
        &.current-cat {
            font-weight: bold;
        }

    }
}


.novetat {
    display: flex;
    flex-direction: column;
    transition: all .2s ease-in-out; 
     &:hover, &:focus {
        //transform: scale(1.1) ; 
        text-decoration: none;
        .foto-image {
            .read-more {
               background: rgba(0,0,0, 0.8);
            }
        }
    }
    .text-white a {
        color: #ffF;
        &:hover, &:focus {
            text-decoration: none;
            color: $primary;
            
        }
    }
    .more {
        a {
            padding: 0.5rem 1rem; 
            display: block;
            border: 1px solid transparent;
            color: #fff;
            background-color: #005035;
            //border-radius: 50rem !important;
            margin: 0 0.5rem 0.5rem 0;
            &:hover, &:focus {
                text-decoration: none;
                color: $secondary;
                
            }
        }
    }
    .foto-image {
        margin: 0;
        position: relative;
        display: flex;
        width: 100%;
        padding: 0;
        overflow: hidden;
        &:before {
            padding-top: 66.666%;
            display: block;
            content: "";
        }
        .img--- {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: auto;
            max-width: 100% !important;
            border: 0;
            transition: all .2s ease-in-out; 
            transform: translate(-50%, -50%);

        }
        img {
            object-fit: cover;
            height: 300px;
        }
        .blog-i {
            object-fit: cover;
            height: 400px;
        }
        .read-more {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: rgba(0,0,0, 0.5);

            color: $white;
            display: flex;
            align-content: center;
            align-items: center;
            justify-content: center;
            width: 100%;
            border: 0;
            text-transform: uppercase;
            //opacity: 0;
            transition: all .2s ease-in-out; 
            //mix-blend-mode: multiply;
        }

    }
    .content {

    }
}






.related {
    border-top: 5px solid $secondary;
    border-bottom: 5px solid $secondary;
}


.cover {
    width: 100%;
    height: 0;
    min-height: 0;
    padding-bottom: 66.666%;
    background-size: cover;
    background-position: center center;
    position: relative;
    background-color: $gray;
    border: 1px solid $white;
    overflow: hidden;
    a {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}




#browsing {
    clear: both;
}

.previouspostslink a, .nextpostslink a {
    color: $body-color;
    &:hover, &:focus {
        color: $secondary;
        text-decoration: none;;
    }
}

.wp-pagenavi {
    width: 100%;
    white-space: nowrap;
    display: flex;
    justify-content: center; 
    position: relative;
    padding:0;
    margin: 2rem 0 0 0;
    color: $body-color;
    clear: both;
    line-height: 1;
    a {
        color: $body-color;
        padding: 0.5em;
        //border-right: 1px solid  #d3ced2;
        &:hover,
        &:focus {
            text-decoration: none;
            color: $primary;
        }
    }

    span {
        &:after, &:before {
            //vertical-align: middle;
        }
    }

    .extend {
    }


    .current {
        padding: 0.5em;
        background: #ebe9eb;
        color: #8a7e88;
    }

    .page {
        
    }

    .previouspostslink {
        //border: 0;
    }

    .nextpostslink {
        border: 0;
  
    }
    .menupostslink {
        //font-size: 2rem;
        //line-height: 1;
    }


}






.share-section {
	position: relative;
}

.card__share {
	position: absolute;
	top: -8px;
	left: -320px;
	width: 300px;
	text-align: right;
}

.share-btn {
	color: $body-color;
    display: flex;
    align-items: center;
	span {
		color: $primary;
	}
	&:hover, &:focus {
		text-decoration: none;
		color: $primary;
	}
}

.share-icon {
	margin-right: 0;
	font-size: 20px;
	width: 32px;
	height: 32px;
	line-height: 32px;
	text-align: center;
	background-color: $gray-600;
	color: #fff;
	display: inline-block;
	&.facebook {
		background-color: $facebook;
	}
	&.twitter {
		background-color: $twitter;
	}
	&.whatsapp {
		background-color: $whatsapp;
	}
	&:hover, &:focus {
		background-color: $primary;
		color: $white;
		text-decoration: none;
	}
}



.gallery {
    margin-right: -15px;
    margin-left: -15px;
    display: flex;
    flex-wrap: wrap;
    .gallery-item {
        padding: 15px;
        width: 100%;
        margin: 0;
        .landscape {
            margin: 0;
            position: relative;
            display: flex;
            width: 100%;
            padding: 0;
            overflow: hidden;
            &:before {
                padding-top: 66.666%;
                display: block;
                content: "";
            }
            img {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 100%;
                height: auto;
                max-width: 100% !important;
                border: 0;
                transition: all .2s ease-in-out; 
                transform: translate(-50%, -50%);

            }
        }
    }
    &.gallery-columns-2 {
        .gallery-item {
            @include media-breakpoint-up(md) {
                width: 50%;
            }
        }
    }
    &.gallery-columns-3 {
        .gallery-item {
            @include media-breakpoint-up(md) {
                width: 33.3333%;
            }
        }
    }
    &.gallery-columns-4 {
        .gallery-item {
            @include media-breakpoint-up(md) {
                width: 25%;
            }
        }
    }
    &.gallery-columns-5 {
        .gallery-item {
            @include media-breakpoint-up(md) {
                width: 20%;
            }
        }
    }
}



.post-nav {
    width: 200px;
    margin: 2rem auto 0 auto;
    display: flex;
    .previouspostslink a, .nextpostslink a{
        width: 100px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #eee;
        &:hover, &:focus {
            background-color: $secondary;
            color: #fff;
        }
    }
}