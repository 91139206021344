/*------------------------------------*\
  PRELOADER
\*------------------------------------*/
.slider-preloader {
    margin: 0;
    width: 100% !important;
    float: left;
    position: relative;
    overflow: hidden;
    clear: both;
    z-index: 800;
    min-height: 100vh;

    @include media-breakpoint-up(lg) {
        //height: 0;
        min-height: 100vh;
        position: relative;
    }
    &.single-preloader {
        height: 0;
        min-height: 0;
        padding-bottom: 66%;
        position: relative;
         @include media-breakpoint-up(lg) {
             padding-bottom: 66%;
        }
    }
}

.preloader-slider {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #9e9e9e;
}

.preloader-slider .preloader-gif {
    border: 5px solid #f3f3f3;
    border-top: 5px solid $primary;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 2s linear infinite;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -30px 0 0 -30px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


/* Portada */
.portada {
    width: 100%;
    position: relative;
    background: $gris;
    height: calc(100vh - 100px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    .portada-image {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-size: cover;
        background-position: center center;
        
    }
    .portada-image-md {
        position: absolute;
        top: 0;
        right: 0;
        height: 600px;
        left: 0;
        background-size: cover;
        background-position: center center;
        
    }
    .portada-cover {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0,0,0,0.5);
        opacity: 1!important;
        mix-blend-mode: multiply;
        transition: 0.25s;
    }
    .portada-cover-md {
        position: absolute;
        top: 0;
        right: 0;
        height: 600px;
        left: 0;
        background-color: rgba(0,0,0,0.5);
        opacity: 1!important;
        mix-blend-mode: multiply;
        transition: 0.25s;
    }
    .portada-text {
        display: flex;
        flex-direction: column;
        min-height: calc(100vh - 50px);
      
        position: relative;
        @include media-breakpoint-up(md) {
            min-height: calc(100vh - 80px);
        }
       
    }
    .portada-title {
        line-height: 1;
        b {
            color: $primary;
        }
    }
    .portada-subtitle {
        text-transform: uppercase;
    }
}

.portada-full {
    .portada-text {
         min-height: 100vh;
         @include media-breakpoint-up(lg) {
            min-height: 100vh;
        }
    }
}

.portada-video {
    width: 100%;
    position: relative;
    background: $gris;
    .portada-image {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-size: cover;
        background-position: center center;
        
    }
    .portada-cover {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0,0,0,0.5);
        opacity: 1!important;
        mix-blend-mode: multiply;
        transition: 0.25s;
    }
    .portada-text {
        display: flex;
        flex-direction: column;
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        top: 0;
        padding-top: 0;
    
    }
    .portada-title {
        b {
            color: $primary !important;
        }
    }
    .portada-subtitle {
        
    }
}


/*------------------------------------*\
  Slide principal
\*------------------------------------*/
.slide-principal, .slide-single {
    .slide {
        position: relative;
    }
    .slide-cover {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0,0,0,0.5);
        opacity: 1!important;
        mix-blend-mode: multiply;
        transition: 0.25s;
    }
    &.shrink {
        .slide-cover {
            opacity: .1!important;
        }
    }

    .slide-content {
        width: 100%;
        background-size: cover;
        background-position: center center;
        color: #fff;
  
        min-height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        @include media-breakpoint-up(lg) {
            height: 100vh;
            min-height: 100vh;
            position: relative;
        }
    }

    .slide-text {
        text-align: center;
        position: relative;
        z-index: 999;
    

            @include media-breakpoint-up(lg) {
                position: absolute;
                bottom: 0;
                right: 0;
                left: 0;
                top: 0;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                text-align: left;
            }
            .btn {
                &:hover, &:focus {
                    background-color: $primary;
                    color: $white;
                    border-color: $primary;
                }
            }


    }

    .owl-nav [class*='owl-'] {
        color: #000 !important;
        width: 70px;
        height: 70px;
        background: #ffffff !important;
        display: none;
        align-items: center;
        justify-content: center;
        vertical-align: middle;
        
        @include media-breakpoint-up(lg) {
            display: flex;
        }
        .ei {
            line-height: 1;
        }
        .ei:before {
            vertical-align: middle;
        }
    }

    .owl-nav .owl-prev {
        left: 0;
    }

    .owl-nav .owl-next {
        right: 0;
    }

    .owl-dots {
        position: absolute;
		bottom: 0.5rem;
		left: 0;
        right: 0;
        margin: 0;
    }

  

    .owl-item .slide .background {
        opacity: 0;
        transform: translate(0, 100%);
    }


    .owl-item.active .slide .background {
        opacity: 1;
        transform: translate(0, 0);
        transition: all 500ms ease 0.8s;
    }

    .owl-item .slide .title {
        opacity: 0;
        transform: translate(50%, 0);
        
    }


    .owl-item.active .slide .title {
        opacity: 1;
        transform: translate(0, 0);
        transition: all 500ms ease 0.8s;
    }

    .owl-item .slide .animation-2 {
        opacity: 0;
        transform: translate(-100%, 0);
        display: flex;
        align-items: center;
    }



    .owl-item.active .slide .animation-2 {
        opacity: 1;
        transform: translate(0, 0);
        transition: all 500ms ease 0.8s;
    }
}


.single-page-head {
    width: 100%;
    background-size: cover;
    background-position: center center;

    min-height: 270px;
    display: flex;
    align-items: center;
    //justify-content: center;
    overflow: hidden;
    position: relative;
    @include media-breakpoint-up(lg) {
        //height: 0;
        //min-height: 0;
        //padding-bottom: 25%;
        //padding-bottom: 41.66666667%;
        position: relative;
    }
    .single-page-text {
        text-align: left;
        position: relative;
        z-index: 10;
        padding-top: 200px;

        @include media-breakpoint-up(lg) {
            
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }
        .btn {
            &:hover, &:focus {
                background-color: $primary;
                color: $white;
                border-color: $primary;
            }
        }
    }
}


.slide-testiomis, .slide-nosaltres, .slide-actividades, .slide-fotos, .slide-noticias, .slide-products  {
    margin: 0;
    clear: both;
    .owl-stage {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        
    }
    .owl-item {
        display: flex;
        
    }
    .product-item {
        width: 100%;
        display: flex;
    }

}


.text-white {
    .owl-carousel {
        .owl-nav {
            .owl-prev {
                color: $white !important;
                
            }

            .owl-next {
                color: $white !important;
            }
        }   
    }

}

.slide-numbers {
    .owl-item  {
        min-height: 70vh;
    }
    .owl-nav {
            position: absolute;
            top: 100%;
            right: 0;
            margin-top: 1rem;
            width: 200px;
            
        .owl-prev {
            float: left;
            top: 25px;
            left: 0;
        }

        .owl-next {
            float: right;
            top: 25px;
            right: 0;
        }
    }
    
    .owl-dots {
		counter-reset: slides-num; /* Initialize counter. */ 
        position: absolute;
        top: 100%;
        left: 0;
        margin-top: 1rem;
     
		&:after {
			content: counter(slides-num); /* get total number of items - more info on http://www.sitepoint.com/a-little-known-way-to-replace-some-scripts-with-css-counters/ */ 
			display: inline-block;
			font-size: 1.728rem;
			vertical-align: middle;
            padding-left: 0.8rem;
		}
        .owl-dot {
            display: inline-block;
            counter-increment: slides-num; /* Increment counter */
            margin-right: 5px;

            span {
                display: none;
            }

            &.active {

                &:before {
                    content:  counter(slides-num) " / " ; /* Use the same counter to get current item. */
                    display: inline-block;
                    vertical-align: middle;
                    font-size: 1.728rem;
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 60px;
                    text-align: left;
                }
            }
        }
	}

	
}



.slide-partners {
   .owl-nav [class*='owl-'] {
           margin: -35px 0 0 0;
   }
}

.slider {
    .owl-item  {
        min-height: 70vh;
    }
    .owl-nav {
            position: absolute;
            top: 100%;
            right: 0;
            margin-top: 1rem;
            width: 150px;
            @include media-breakpoint-up(lg) {
                width: 200px;
            }
        .owl-prev {
            float: left;
            top: 40px;
            left: 0;
            @include media-breakpoint-up(lg) {
                top: 25px;
            }
        }

        .owl-next {
            float: right;
            top: 40px;
            right: 0;
            @include media-breakpoint-up(lg) {
                top: 25px;
            }
        }
    }
    
    .owl-dots {
		display: none;
	}
    
	
}

.slider-counter {
    //position: absolute;
    //top: 100%;
    //left: 0;
    margin-top: 1rem;
    text-align: left;
    font-size: 40px;
    max-width: 200px;
}


.last-blog, .slide-servicios, .slide-actividades, .slide-fotos, .slide-noticias, .slide-products  {
    margin: 0;
    clear: both;
    .owl-stage-outer {
        overflow: visible;
    }
    .owl-stage {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        
        
    }
    .owl-item {
        display: flex;
        opacity: 0.7;
        transition: opacity .2s linear;
        &.active {
            opacity: 1;
        }
        
    }
    .product-item {
        width: 100%;
        display: flex;
    }

}




/* This is the SCSS you'll need */
.video-wrapper {
  position: relative;
  width: 100%;
  min-height: 100%;
  overflow: hidden;
}

.video-overlay {
  height: 100%;
  width: 100%;
  opacity: .8;
  position: absolute;
  z-index: 2;
}

.video-expand {
  color: white;
  font-size: 1.125rem;
  line-height: 1em;
  opacity: .4;
  position: absolute;
  bottom: 0.9375rem;
  right: 0.9375rem;
  z-index: 900;
  display: none;
}
.video-expand:hover {
  transition: all 400ms ease-in-out;
}

.tv {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.tv .screen {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  margin: auto;
  opacity: 0;
  transition: opacity .5s;
}
.tv .screen.active {
  opacity: 1;
}