


textarea.form-control {
    min-height: 185px !important;
    height: 185px ;
    //border: 1px solid $primary;
}



// ctf7
.input-group-icon {
    display: flex;
    align-items: center;
    padding-left: 10px;

    span {
        font-size: 1.5rem;
        color: $primary;
    }
}

span.wpcf7-list-item {
    margin: 0 0 0 1px !important;
    display: block !important;
    label {
        margin: 0;
    }
}

.wpcf7-list-item-label {
    padding-left: 23px;
}


.option-left-normal {
    overflow: hidden;

    input[type=radio],
    input[type=checkbox] {
        display: none;
    }

    input[type=radio]+label,
    input[type=checkbox]+label {
        padding: 0 0 0 22px;
        margin: 0 auto;
        display: inline-block;
        cursor: pointer;
        position: relative;
    }

    input[type=checkbox]+label:before, input[type=checkbox]+.wpcf7-list-item-label:before {
        content: '';
        border-radius: 0;
    }

    input[type=checkbox]:checked+label:before,  input[type=checkbox]:checked+.wpcf7-list-item-label:before {
        font-family: 'ElegantIcons';
        content: "\4e";
        background-color: $verde;
        border: 1px solid $verde;
        color: $white;
        line-height: 1;

    }

    input[type=radio]+label:before {
        content: '';
        border-radius: 50%;
        cursor: pointer;
    }

    input[type=radio]+label:before,
    input[type=checkbox]+label:before {
        //font-size: 12px;
        //line-height: 14px;
        //color: #000;
        //text-align: center;
    }

    label {
        display: inline-block;
        //float: left;
        cursor: pointer;
        position: relative;



        &:before {
            float: left;
            content: '';
            display: inline-block;
            width: 15px;
            height: 15px;
            margin: 0;

            background-color: $white;
            border: 1px solid $gray-500;
            position: absolute;
            top: 4px;
            left: 0;
        }
    }
    .wpcf7-list-item-label {
        display: inline-block;
        //float: left;
        cursor: pointer;
        position: relative;



        &:before {
            float: left;
            content: '';
            display: inline-block;
            width: 15px;
            height: 15px;
            margin: 0;

            background-color: $white;
            border: 1px solid $gray-500;
            position: absolute;
            top: 4px;
            left: 0;
        }
    }
}




.mc4wp-response {
    margin: 2rem auto;
    text-align: center;
}




[type="file"] {
    font-size: 12px;
    color: #878787;
}

[type="file"]::-webkit-file-upload-button {
    background: $primary;
    border: 1px solid $primary;
    border-radius: 0;
    color: #fff;
    cursor: pointer;
    font-size: 12px;
    outline: none;
    padding: .375rem 1.5rem;;
    text-transform: uppercase;
    transition: all 1s ease;
    min-width: 200px;
    
}

[type="file"]::-webkit-file-upload-button:hover {
    background: #fff;
    border: 1px solid #535353;
    color: #000;
}