// Preloader
.preloader {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #fff;
    z-index: 99999;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

}


.preloader-content img {
    display: block;
    margin-bottom: 30px;

}

.preloader .icon-bcool {

    display: inline-block;
    border: 10px solid #f3f3f3;
    /* Light grey */
    border-top: 10px solid $primary;
    /* Blue */
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 2s linear infinite;
    margin: 0 auto;


}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}






// https://codepen.io/dissimulate/pen/vlfyA
#load div {
  position:absolute;
  width:20px;
  height:36px;
  opacity:0;
  font-family:Helvetica, Arial, sans-serif;
  animation:move 2s linear infinite;
  transform:rotate(180deg);
  color: $primary;
}

#load div:nth-child(2) {
  animation-delay:0.2s;
}
#load div:nth-child(3) {
  animation-delay:0.4s;
}
#load div:nth-child(4) {
  animation-delay:0.6s;
}
#load div:nth-child(5) {
  animation-delay:0.8s;
}
#load div:nth-child(6) {
  animation-delay:1s;
}
#load div:nth-child(7) {
  animation-delay:1.2s;
}

@keyframes move {
  0% {
    left:0;
    opacity:0;
  }
	35% {
		left: 41%; 
		transform:rotate(0deg);
		opacity:1;
	}
	65% {
		left:59%; 
		transform:rotate(0deg); 
		opacity:1;
	}
	100% {
		left:100%; 
		transform:rotate(-180deg);
		opacity:0;
	}
}



// link line
.special a {
    text-decoration: none;
    background-image: linear-gradient(currentColor, currentColor);
    background-position: 0% 100%;
    background-repeat: no-repeat;
    background-size: 30% 2px;
    transition: background-size cubic-bezier(0,.5,0,1) 0.3s;
}

.special a:hover,
.special a:focus {
    color: $primary;
    text-decoration: none;
    background-size: 100% 2px;
}


// best link line
.actions a, .action {
    position: relative;
	font-weight: bold;
	em {
		width: 28px;
		height: 2px;
		position: absolute;
		bottom: -6px;
		left: 0;
		background: $primary;
		font-size: 1px;
		transition: all .2s ease-in-out; 
	}
    &:hover, &:focus {
        text-decoration: none;
        color: $primary;
		em {
			width: 100%;
		}

    }
}





.servei-img {
	opacity: 0;
  	transform: translate(0, 20%) translate3d(0, 0, 0px);
    transition: all .5s ease-in-out; 
	//animation-delay:3.2s;
	//transition-delay: .2s;
	&.is-visible {
		opacity: 1;
  		transform: translate(0%, 0) translate3d(0px, 0, 0px);
	}
}

@keyframes height {
  0% {
    height:0;
    opacity:0;
  }
	35% {
		height: 41%; 
		opacity:1;
	}
	65% {
		height: 51%;
	}
	100% {
		height: auto;
	}
}





// https://codepen.io/bramus/pen/yikfd

.animatable {
  
  /* initially hide animatable objects */
  visibility: hidden;
  
  /* initially pause animatable objects their animations */
  -webkit-animation-play-state: paused;   
  -moz-animation-play-state: paused;     
  -ms-animation-play-state: paused;
  -o-animation-play-state: paused;   
  animation-play-state: paused; 
}

/* show objects being animated */
.animated {
  visibility: visible;
  
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -ms-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;

  -webkit-animation-play-state: running;
  -moz-animation-play-state: running;
  -ms-animation-play-state: running;
  -o-animation-play-state: running;
  animation-play-state: running;
}

/* CSS Animations (extracted from http://glifo.uiparade.com/) */
@-webkit-keyframes fadeInDown {
	0% {
		opacity: 0;
		-webkit-transform: translateY(-20px);
	}	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
	}
}

@-moz-keyframes fadeInDown {
	0% {
		opacity: 0;
		-moz-transform: translateY(-20px);
	}

	100% {
		opacity: 1;
		-moz-transform: translateY(0);
	}
}

@-o-keyframes fadeInDown {
	0% {
		opacity: 0;
		-o-transform: translateY(-20px);
	}

	100% {
		opacity: 1;
		-o-transform: translateY(0);
	}
}

@keyframes fadeInDown {
	0% {
		opacity: 0;
		transform: translateY(-20px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}



@-webkit-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	20% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-moz-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	20% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-o-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	20% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	60% {
		opacity: 0;
	}
	20% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@-webkit-keyframes bounceInLeft {
	0% {
		opacity: 0;
		-webkit-transform: translateX(-2000px);
	}
	60% {
		-webkit-transform: translateX(20px);
	}

	80% {
		-webkit-transform: translateX(-5px);
	}

	100% {
		opacity: 1;
		-webkit-transform: translateX(0);
	}
}

@-moz-keyframes bounceInLeft {
	0% {
		opacity: 0;
		-moz-transform: translateX(-2000px);
	}

	60% {
		-moz-transform: translateX(20px);
	}

	80% {
		-moz-transform: translateX(-5px);
	}

	100% {
		opacity: 1;
		-moz-transform: translateX(0);
	}
}

@-o-keyframes bounceInLeft {
	0% {
		opacity: 0;
		-o-transform: translateX(-2000px);
	}

	60% {
		opacity: 1;
		-o-transform: translateX(20px);
	}

	80% {
		-o-transform: translateX(-5px);
	}

	100% {
		opacity: 1;
		-o-transform: translateX(0);
	}
}

@keyframes bounceInLeft {
	0% {
		opacity: 0;
		transform: translateX(-2000px);
	}

	60% {
		transform: translateX(20px);
	}

	80% {
		transform: translateX(-5px);
	}

	100% {
		opacity: 1;
		transform: translateX(0);
	}
}
@-webkit-keyframes bounceInRight {
	0% {
		opacity: 0;
		-webkit-transform: translateX(2000px);
	}

	60% {
		-webkit-transform: translateX(-20px);
	}

	80% {
		-webkit-transform: translateX(5px);
	}

	100% {
		opacity: 1;
		-webkit-transform: translateX(0);
	}
}

@-moz-keyframes bounceInRight {
	0% {
		opacity: 0;
		-moz-transform: translateX(2000px);
	}

	60% {
		-moz-transform: translateX(-20px);
	}

	80% {
		-moz-transform: translateX(5px);
	}

	100% {
		opacity: 1;
		-moz-transform: translateX(0);
	}
}

@-o-keyframes bounceInRight {
	0% {
		opacity: 0;
		-o-transform: translateX(2000px);
	}

	60% {
		-o-transform: translateX(-20px);
	}

	80% {
		-o-transform: translateX(5px);
	}

	100% {
		opacity: 1;
		-o-transform: translateX(0);
	}
}

@keyframes bounceInRight {
	0% {
		opacity: 0;
		transform: translateX(2000px);
	}

	60% {
		transform: translateX(-20px);
	}

	80% {
		transform: translateX(5px);
	}

	100% {
		opacity: 1;
		transform: translateX(0);
	}
}
@-webkit-keyframes fadeInUp {
	0% {
		opacity: 0;
		-webkit-transform: translateY(20px);
	}	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
	}
}

@-moz-keyframes fadeInUp {
	0% {
		opacity: 0;
		-moz-transform: translateY(20px);
	}

	100% {
		opacity: 1;
		-moz-transform: translateY(0);
	}
}

@-o-keyframes fadeInUp {
	0% {
		opacity: 0;
		-o-transform: translateY(20px);
	}

	100% {
		opacity: 1;
		-o-transform: translateY(0);
	}
}

@keyframes fadeInUp {
	0% {
		opacity: 0;
		transform: translateY(20px);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}
@-webkit-keyframes bounceIn {
	0% {
		opacity: 0;
		-webkit-transform: scale(.3);
	}
	50% {
		-webkit-transform: scale(1.05);
	}

	70% {
		-webkit-transform: scale(.9);
	}

	100% {
		opacity: 1;
		-webkit-transform: scale(1);
	}
}

@-moz-keyframes bounceIn {
	0% {
		opacity: 0;
		-moz-transform: scale(.3);
	}

	50% {
		-moz-transform: scale(1.05);
	}

	70% {
		-moz-transform: scale(.9);
	}

	100% {
		opacity: 1;
		-moz-transform: scale(1);
	}
}

@-o-keyframes bounceIn {
	0% {
		opacity: 0;
		-o-transform: scale(.3);
	}

	50% {
		-o-transform: scale(1.05);
	}

	70% {
		-o-transform: scale(.9);
	}

	100% {
		opacity: 1;
		-o-transform: scale(1);
	}
}

@keyframes bounceIn {
	0% {
		opacity: 0;
		transform: scale(.3);
	}

	50% {
		transform: scale(1.05);
	}

	70% {
		transform: scale(.9);
	}

	100% {
		opacity: 1;
		transform: scale(1);
	}
}
@-webkit-keyframes moveUp {
	0% {
		opacity: 1;
		-webkit-transform: translateY(40px);
	}	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
	}
}

@-moz-keyframes moveUp {
	0% {
		opacity: 1;
		-moz-transform: translateY(40px);
	}

	100% {
		opacity: 1;
		-moz-transform: translateY(0);
	}
}

@-o-keyframes moveUp {
	0% {
		opacity: 1;
		-o-transform: translateY(40px);
	}

	100% {
		opacity: 1;
		-o-transform: translateY(0);
	}
}

@keyframes moveUp {
	0% {
		opacity: 1;
		transform: translateY(40px);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@-webkit-keyframes fadeBgColor {
	0%{
		background:none;
	}
  70%{
		background:none;
	}
	100%{
		background:#464646;
	}
}
@-o-keyframes fadeBgColor {
	0%{
		background:none;
	}
  70%{
		background:none;
	}
	100%{
		background:#464646;
	}
}
@keyframes fadeBgColor {
	0%{
		background:none;
	}
  70%{
		background:none;
	}
	100%{
		background:#464646;
	}
}

.animated.animationDelay{
	animation-delay:.4s;
	-webkit-animation-delay:.4s;
}
.animated.animationDelayMed{
	animation-delay:1.2s;
	-webkit-animation-delay:1.2s;
}
.animated.animationDelayLong{
	animation-delay:1.6s;
	-webkit-animation-delay:1.6s;
}
.animated.fadeBgColor {
	-webkit-animation-name: fadeBgColor;
	-moz-animation-name: fadeBgColor;
	-o-animation-name: fadeBgColor;
	animation-name: fadeBgColor;
}
.animated.bounceIn {
	-webkit-animation-name: bounceIn;
	-moz-animation-name: bounceIn;
	-o-animation-name: bounceIn;
	animation-name: bounceIn;
}
.animated.bounceInRight {
	-webkit-animation-name: bounceInRight;
	-moz-animation-name: bounceInRight;
	-o-animation-name: bounceInRight;
	animation-name: bounceInRight;
}
.animated.bounceInLeft {
	-webkit-animation-name: bounceInLeft;
	-moz-animation-name: bounceInLeft;
	-o-animation-name: bounceInLeft;
	animation-name: bounceInLeft;
}
.animated.fadeIn {
	-webkit-animation-name: fadeIn;
	-moz-animation-name: fadeIn;
	-o-animation-name: fadeIn;
	animation-name: fadeIn;
}
.animated.fadeInDown {
	-webkit-animation-name: fadeInDown;
	-moz-animation-name: fadeInDown;
	-o-animation-name: fadeInDown;
	animation-name: fadeInDown;
}
.animated.fadeInUp {
	-webkit-animation-name: fadeInUp;
	-moz-animation-name: fadeInUp;
	-o-animation-name: fadeInUp;
	animation-name: fadeInUp;
}
.animated.moveUp {
	-webkit-animation-name: moveUp;
	-moz-animation-name: moveUp;
	-o-animation-name: moveUp;
	animation-name: moveUp;
}